import React from 'react'
import { Link } from "react-router-dom";
import optelmaImage from "../assets/img/portfolio/optelma.png"
import gerenciaImage from "../assets/img/portfolio/gerencia.png"
import VocaddImage from "../assets/img/portfolio/Vocadd.png"
import moveUImage from '../assets/img/portfolio/MoveU.jpg'
import eventImage from "../assets/img/portfolio/Event.webp"
import ecoFNBImage from "../assets/img/portfolio/EcoFNB.jpeg"
import safai from "../assets/img/Screenshot (24) copy.png"
import puppy from "../assets/img/imgonline-com-ua-twotoone-ykRKwYwKYTjn.jpg"
import xyxx from "../assets/img/imgonline-com-ua-twotoone-pzngpbLqHUJfEe.jpg"
import shopi from "../assets/img/xyxx.jpg"
import online from "../assets/img/imgonline-com-ua-twotoone-O4kBPGyPda8uE.jpg"
import market1 from "../assets/img/imgonline-com-ua-twotoone-IpqbOYyW7GbH.jpg"
import podcast from "../assets/img/Screenshot 2024-07-15 at 12.43.14 AM.png"




function Portfolio() {
  return (
    <>
      <section id="portfolio" className="portfolio sections-bg">
        <div className="container" data-aos="fade-up">  
          <div className="section-header">
            <h2>Portfolio</h2>
            <p>At Ryientsoft Tech, we specialize in delivering cutting-edge 
              software solutions tailored to meet the unique needs of our clients.
               Our portfolio showcases a diverse range of projects that highlight our 
               expertise in innovative technology, custom software development, 
               and exceptional client service.</p>
          </div>
          <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order" data-aos-delay="100">
            {/* <div>
              <ul className="portfolio-flters">
                <li data-filter="*" className="filter-active">All</li>
              </ul>
            </div> */}


            

            <div className="row gy-4 portfolio-container">



            <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                <a href="#" data-gallery="portfolio-gallery-app" className="glightbox">
                   <img src={xyxx} className="img-fluid" alt="" />
                 </a>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">Shoppi App</h4>
                    <p> The Shoppi App simplifies shopping. Browse products, create wishlists, track orders, and enjoy personalized recommendations, secure payments, and exclusive deals all in one place.</p>
                  </div>
                </div>
              </div>


              <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                <a href="#" data-gallery="portfolio-gallery-app" className="glightbox">
                   <img src={shopi} className="img-fluid" alt="" />
                 </a>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">SafaiXYX app</h4>
                    <p> 
                    The SafaiXYX App is a user-friendly mobile application designed to streamline waste management and recycling. It allows users to schedule pickups, track collection times, and receive notifications for waste disposal services.</p>
                  </div>
                </div>
              </div>


              <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                <a href="#" data-gallery="portfolio-gallery-app" className="glightbox">
                   <img src={puppy} className="img-fluid" alt="" />
                 </a>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">ABC Puppy app</h4>
                    <p> The ABC Puppy App is a fun and interactive mobile application for puppy lovers. It offers essential tips on training, health care, and grooming, along with a wealth of playful activities and games for puppies. </p>
                  </div>
                </div>
              </div>



              <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                <a href="#" data-gallery="portfolio-gallery-app" className="glightbox">
                   <img src={market1} className="img-fluid" alt="" />
                 </a>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">Market Place app</h4>
                    <p>
                    The Market Place App is a versatile mobile platform that connects buyers and sellers effortlessly. Explore a wide range of products, from local goods to global items, with features for secure transactions, reviews, and personalized recommendations.</p>
                  </div>
                </div>
              </div>


              <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                <a href="#" data-gallery="portfolio-gallery-app" className="glightbox">
                   <img src={online} className="img-fluid" alt="" />
                 </a>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">Online Payment App</h4>
                    <p>The Online Payment App simplifies transactions by allowing users to send and receive money securely from their mobile devices. With features like instant transfers, bill payments, and transaction tracking, it offers a convenient and safe way to manage your finances anytime, anywhere..</p>
                  </div>
                </div>
              </div>


              <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <Link to={'#'} data-gallery="portfolio-gallery-app" className="glightbox">
                    <img src={podcast} className="img-fluid" alt="" />
                  </Link>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">PodCast App</h4>
                    <p> A podcast app that allows users to discover, subscribe to, and listen to their favorite podcasts. The app features an intuitive interface for browsing various categories, personalized recommendations, offline listening, and easy management of subscriptions.</p>
                  </div>
                </div>
              </div>



            <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <Link to={'#'} data-gallery="portfolio-gallery-app" className="glightbox">
                    <img src={safai} className="img-fluid" alt="" />
                  </Link>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">SAFAI XYZ</h4>
                    <p> Safai XYZ offers comprehensive cleaning services tailored to residential and commercial spaces. With a focus on efficiency and quality, we provide customizable cleaning solutions that ensure a pristine environment for our clients.</p>
                  </div>
                </div>
              </div>



              <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <Link to={'#'} data-gallery="portfolio-gallery-app" className="glightbox">
                    <img src={optelmaImage} className="img-fluid" alt="" />
                  </Link>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none' title="More Details"> OPTELMA</h4>
                    <p>Optelma Lighting Solutions seeks a website redesign to improve user experience, showcase products, and generate leads. Objectives include
                      enhanced navigation, product showcase, content management, lead generation, SEO optimization, and responsive design.</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                <div className="portfolio-wrap">
                  <Link to={'#'} data-gallery="portfolio-gallery-app" className="glightbox">
                    <img src={gerenciaImage} className="img-fluid" alt="" />
                  </Link>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">GERENCIA RED</h4>
                    <p>Gerencia Red is a web-based network management tool designed to
                      streamline the monitoring and administration of complex network infrastructures.
                      With a user-friendly interface and powerful features, Gerencia Red empowers network.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                <div className="portfolio-wrap">
                  <Link to={'#'} data-gallery="portfolio-gallery-app" className="glightbox">
                    <img src={VocaddImage} className="img-fluid" alt="" />
                  </Link>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">VOCADD</h4>
                    <p>VocAdd is a vocational skills enhancement platform designed to empower
                      individuals with essential job-related skills. It offers a range of online
                      courses, workshops, and resources tailored to various industries and career paths,
                      with a focus on enhancing employability .
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                <div className="portfolio-wrap">
                  <Link to={'#'} data-gallery="portfolio-gallery-app" className="glightbox">
                    <img src={moveUImage} className="img-fluid" alt="" />
                  </Link>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">Move U</h4>
                    <p>Move U is a fitness app designed to help users achieve their health and wellness goals through personalized workout plans, nutrition tracking, and community support. It offers a user-friendly interface, customizable workouts, progress tracking,
                      and social features to motivate and engage users on their fitness journey.</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <Link to={'#'} data-gallery="portfolio-gallery-app" className="glightbox">
                    <img src={eventImage} className="img-fluid" alt="" />
                  </Link>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">EVENTAPP</h4>
                    <p> EventHive is an innovative event management app designed to streamline the planning and organization of both small-scale and large-scale events. It offers features such as event scheduling, attendee management,real-time communication, and analytics, providing event
                      organizers with the tools they need to create.</p>
                  </div>
                </div>
              </div>


              
              {/* <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                <div className="portfolio-wrap">
                  <Link to={ecoFNBImage} data-gallery="portfolio-gallery-app" className="glightbox">
                    <img src={ecoFNBImage} className="img-fluid" alt="" />
                  </Link>
                  <div className="portfolio-info">
                    <h4 className='text-decoration-none'title="More Details">ECOFNB</h4>
                    <p>ECOFNB is a sustainable food delivery platform that connects users with local restaurants offering eco-friendly and ethically sourced meals. It emphasizes sustainability by promoting reusable packaging, reducing food waste, and supporting
                      environmentally conscious practices throughout the food supply chain.</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Portfolio